import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { deleteData, postData, putData, fetchData } from "../../endpoints";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  UnlockFilled,
  LockFilled,
} from "@ant-design/icons";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useMobile } from "../../hooks";

const NewQuestionnaire = ({
  template,
  setIsOpen,
  setShowReport,
  setSelectedReport,
  isEdit,
}) => {
  const isMobile = useMobile();
  const [questionnaire, setQuestionnaire] = useState(
    template
      ? template.sections
        ? template.sections.map((s) => {
            var q = s.questions.map((qu) => {
              return { ...qu, title: qu.question };
            });
            return { ...s, questions: q };
          })
        : []
      : []
  );
  const [title, setTitle] = useState(template ? template.title : null);
  const [commands, setCommands] = useState([]);
  const [addComand, setAddComand] = useState(false);
  const [editComandModal, setEditComand] = useState(false);
  const [selected, setSelected] = useState("");
  const [newCommand, setNewCommand] = useState("");
  const [addQuestion, setAddQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [editSectionModal, seteditSectionModal] = useState(false);
  const [q, setQ] = useState({});
  const [myForm] = useForm();
  const [editLoading, setEditLoading] = useState(false);
  const [isSave, setIsSave] = useState(true);
  useEffect(() => {
    var temp = [];
    questionnaire.map((s) => {
      var not_repete = s.commands.filter((s1, j) => {
        for (var i = 0; i < j; i++) {
          if (s.commands[i].prompt == s1.prompt) {
            return false;
          }
        }
        return true;
      });
      temp = [
        ...temp,
        ...not_repete.filter((s1) => {
          for (var i in temp) {
            if (temp[i].prompt == s1.prompt) {
              return false;
            }
          }
          return true;
        }),
      ];
    });
    setCommands(temp);
  }, [addComand]);
  const getTamplate = async (id) => {
    var res = await fetchData("admin/report/" + id);
    if (res.status == 200) {
      setQuestionnaire(
        res.data.data.sections.map((s) => {
          var q = s.questions.map((qu) => {
            return { ...qu, title: qu.question };
          });
          return { ...s, questions: q };
        })
      );
    }
  };
  const saveTemplate = async () => {
    saveReport(true, true);
  };
  const saveReport = async (istemplate = false, newTemp = false) => {
    setIsLoading(true);
    if (isEdit && !newTemp) {
      var res = await putData("admin/report/" + template.id, {
        title: title,
        template: template.template ? true : istemplate,
      });
      if (res.status !== 200) {
        Modal.error({ title: res });
      }
      for (var h in questionnaire) {
        var element = questionnaire[h];
        if (element.id) {
          await editSection(element);
          for (var i in element.commands) {
            var command = element.commands[i];
            if (command.id) {
              await editCommand(command);
            } else {
              var res3 = await postData("admin/report-command", {
                priority: parseInt(i),
                sectionId: element.id,
                prompt: command.prompt,
                title: command.title,
              });
            }
          }
          for (var i in element.questions) {
            var q = element.questions[i];
            if (q.id) {
              await editQuestion(q);
            } else {
              var res4 = await postData("admin/report-question", {
                question: q.title,
                sectionId: element.id,
                priority: parseInt(i),
                isSensitive: q.isSensitive ?? false,
              });
              if (res4.status != 201) {
                Modal.error({
                  title:
                    "for qustion" +
                    i +
                    " in section" +
                    element.id +
                    ": " +
                    res4,
                });
              }
            }
          }
        } else {
          var res2 = await postData("admin/report-section", {
            reportId: template.id,
            priority: parseInt(h),
            title: element.title,
          });
          if (res2.status != 201) {
            Modal.error({ title: "for section" + element.id + ": " + res2 });
          } else {
            for (var i in element.commands) {
              var command = element.commands[i];
              var res3 = await postData("admin/report-command", {
                priority: parseInt(i),
                sectionId: res2.data.data.id,
                prompt: command.prompt,
                title: "Command",
              });
            }
            for (var i in element.questions) {
              var q = element.questions[i];
              var res4 = await postData("admin/report-question", {
                question: q.title,
                sectionId: res2.data.data.id,
                priority: parseInt(i),
              });
              if (res4.status != 201) {
                Modal.error({
                  title: "for qustion" + i + " in section" + h + ": " + res4,
                });
              }
            }
          }
        }
      }
      await getTamplate(template.id);
      setIsSave(true);
      setIsLoading(false);
    } else {
      var res = await postData("admin/report", {
        template: istemplate,
        title: title,
      });
      if (res.status == 201) {
        for (var h in questionnaire) {
          var element = questionnaire[h];
          var res2 = await postData("admin/report-section", {
            reportId: res.data.data.id,
            priority: parseInt(h),
            title: element.title,
          });
          if (res2.status != 201) {
            Modal.error({ title: "for section" + h + ": " + res2 });
          } else {
            for (var i in element.commands) {
              var command = element.commands[i];
              var res3 = await postData("admin/report-command", {
                priority: parseInt(i),
                sectionId: res2.data.data.id,
                prompt: command.prompt,
                title: command.title,
              });
              if (res3.status != 201) {
                Modal.error({
                  title:
                    "error in command" + i + " in section" + h + ": " + res3,
                });
              }
            }

            for (var i in element.questions) {
              var q = element.questions[i];
              var res4 = await postData("admin/report-question", {
                question: q.title,
                sectionId: res2.data.data.id,
                priority: parseInt(i),
              });
              if (res4.status != 201) {
                Modal.error({
                  title:
                    "error in qustion" + i + " in section" + h + ": " + res4,
                });
              }
            }
          }
        }
        await getTamplate(res.data.data.id);
        setIsSave(true);
        setIsLoading(false);
        setIsOpen(false);
      } else {
        Modal.error({ title: res });
      }
    }
    setIsLoading(false);
  };

  const editQuestion = async (q) => {
    // setIsLoading(true);
    var res = await putData("admin/report-question/" + q.id, q);
    if (res.status == 200) {
      // message.success(res.data.message);
    } else {
      Modal.error({ title: res });
    }
    // setIsLoading(false);
  };
  const editCommand = async (c) => {
    // setIsLoading(true);
    var res = await putData("admin/report-command/" + c.id, c);
    if (res.status == 200) {
    } else {
      Modal.error({ title: res });
    }
    // setIsLoading(false);
  };
  const editSection = async (s) => {
    // setIsLoading(true);
    var res = await putData("admin/report-section/" + s.id, s);
    if (res.status == 200) {
      message.success(res.data.message);
    } else {
      Modal.error({ title: res });
    }
    // setIsLoading(false);
  };
  const showDeleteConfirm = async (item, type, index = 0) => {
    Modal.confirm({
      title: "Are you sure remove this?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setIsSave(false);
        if (type == "section") {
          deleteSection(item);
        } else if (type == "command") {
          deleteCommand(item, index);
        } else {
          deleteQuestion(item, index);
        }
      },
      onCancel() {},
    });
  };
  const deleteSection = async (s) => {
    setIsLoading(true);
    if (isEdit) {
      var res = await deleteData("admin/report-section/" + s.id);
      if (res.status == 200) {
        setQuestionnaire(
          questionnaire.filter((se) => {
            return se.id != s.id;
          })
        );
        message.success(res.data.message);
      } else {
        Modal.error({ title: res });
      }
    } else {
      setQuestionnaire(
        questionnaire.filter((se) => {
          return se.id != s.id;
        })
      );
    }
    setIsLoading(false);
  };
  const deleteCommand = async (c, index) => {
    setIsLoading(true);
    if (c.id) {
      var res = await deleteData("admin/report-command/" + c.id);
      if (res.status == 200) {
        var temp = questionnaire;
        temp[index] = {
          ...temp[index],
          commands: temp[index].commands.filter((com) => {
            return c.id != com.id;
          }),
        };
        setQuestionnaire(temp);
        // message.success(res.data.message);
      } else {
        Modal.error({ title: res });
      }
    } else {
      var temp = questionnaire;
      temp[index] = {
        ...temp[index],
        commands: temp[index].commands.filter((com) => {
          return c.title != com.title || c.prompt != com.prompt;
        }),
      };
      await setQuestionnaire(temp);
    }
    setIsLoading(false);
  };

  const deleteQuestion = async (q, index) => {
    setIsLoading(true);
    if (isEdit) {
      var res = await deleteData("admin/report-question/" + q.id);
      if (res.status == 200) {
        var temp = questionnaire;
        temp[index] = {
          ...temp[index],
          questions: temp[index].questions.filter((h, j) => {
            return h.id != q.id;
          }),
        };
        setQuestionnaire(temp);
        // message.success(res.data.message);
      } else {
        Modal.error({ title: res });
      }
    } else {
      var temp = questionnaire;
      temp[index] = {
        ...temp[index],
        questions: temp[index].questions.filter((h, j) => {
          return h.id != q.id;
        }),
      };
      setQuestionnaire(temp);
    }
    setIsLoading(false);
  };
  const navigator = useNavigate();
  const path = useLocation().pathname;
  const { reportId } = useParams();
  return (
    <div className="report px-5 py-5">
      <svg
        style={{ marginBottom: "20px" }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          if (reportId) {
            navigator(path.replace("/" + reportId, ""));
          }
          setIsOpen(false);
        }}
      >
        <path
          d="M22.7996 11.3956H2.65334L10.0208 4.01421C10.2528 3.78219 10.2528 3.40603 10.0208 3.17401C9.7889 2.942 9.41285 2.942 9.18091 3.17401L0.781469 11.576C0.665495 11.6847 0.599609 11.8369 0.599609 11.996C0.599609 12.1552 0.665498 12.3074 0.781469 12.4161L9.18091 20.8181C9.28965 20.9341 9.44178 21 9.60088 21C9.75997 21 9.91211 20.9341 10.0208 20.8181C10.1368 20.7093 10.2027 20.5571 10.2027 20.398C10.2027 20.2388 10.1368 20.0867 10.0208 19.9779L2.65334 12.5965H22.7996C23.131 12.5965 23.3996 12.3278 23.3996 11.9964C23.3996 11.665 23.131 11.3963 22.7996 11.3963V11.3956Z"
          fill="#18324E"
        />
      </svg>
      <h1>Questionnaire</h1>
      <div className="d-flex flex-row gap-2 mt-3">
        <label style={{ fontWeight: "600", fontSize: "18px" }}>Title: </label>
        <Input
          onChange={(e) => {
            setIsSave(false);
            setTitle(e.target.value);
          }}
          value={title}
        />
      </div>
      <div className="d-flex flex-column clo-12 my-4">
        <div className="body-part">
          {questionnaire.length == 0 ? (
            " Here, First add questions, or import an assessment."
          ) : editLoading ? (
            <Spin />
          ) : (
            questionnaire.map((element, i) => {
              return (
                <Section
                  section={questionnaire[i]}
                  questionnaire={questionnaire}
                  setQuestionnaire={setQuestionnaire}
                  index={i}
                  setEditLoading={setEditLoading}
                  editLoading={editLoading}
                  showDeleteConfirm={showDeleteConfirm}
                  seteditSectionModal={seteditSectionModal}
                  setQ={setQ}
                  setIsSave={setIsSave}
                  isMobile={isMobile}
                  setEditComand={setEditComand}
                />
              );
            })
          )}
        </div>
        <div
          style={{ height: "40px" }}
          className="d-flex flex-row col-12 mt-auto"
        >
          <span
            className="col-4 addButton"
            onClick={() => {
              setIsSave(false);
              setAddSection(true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                fill="#2E6CDF"
              />
            </svg>
            Add Section
          </span>
          <span
            className="col-4 addButton"
            onClick={() => {
              setIsSave(false);
              if (questionnaire.length > 0) setAddQuestion(true);
              else {
                Modal.error({ title: "Please add section first!" });
              }
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                fill="#2E6CDF"
              />
            </svg>
            Add Question
          </span>
          <span
            className="col-4 addButton"
            onClick={() => {
              setIsSave(false);
              if (questionnaire.length > 0) setAddComand(true);
              else {
                Modal.error({ title: "Please add section first!" });
              }
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2942 7.29424H8.70576V0.705756C8.70576 0.315785 8.38964 0 8 0C7.61003 0 7.29424 0.315785 7.29424 0.705756V7.29424H0.705756C0.315785 7.29424 0 7.61003 0 8C0 8.38997 0.315785 8.70576 0.705756 8.70576H7.29424V15.2942C7.29424 15.6839 7.61003 16 8 16C8.38964 16 8.70576 15.6839 8.70576 15.2942V8.70576H15.2942C15.6839 8.70576 16 8.38997 16 8C16 7.61003 15.6839 7.29424 15.2942 7.29424Z"
                fill="#2E6CDF"
              />
            </svg>
            Add Command
          </span>
        </div>
      </div>
      <div
        dir="rtl"
        style={{ flexWrap: "wrap" }}
        className={" d-flex flex-row gap-3 mb-5 col-12"}
      >
        <Button
          type="primary"
          size={isMobile ? "middle" : "large"}
          onClick={async () => {
            console.log(path);
            navigator(path+"/confirm")
            if (!isSave) {
              setIsLoading(true);
              await saveReport();
              setSelectedReport({
                sections: questionnaire,
                title: title,
                id: template.id,
                results: template.results ?? [],
              });
              setShowReport(true);
              setIsLoading(false);
              // Modal.confirm({
              //   title: "Are you sure to confirm befor save your new data?",
              //   onOk() {
              //     setSelectedReport({
              //       sections: questionnaire,
              //       title: title,
              //       id: template.id,
              //       results: template.results ?? [],
              //     });
              //     setShowReport(true);
              //   },
              //   icon: <ExclamationCircleFilled />,
              //   okText: "Yes",
              //   okType: "danger",
              //   cancelText: "No",
              //   onCancel() {},
              // });
            } else {
              setSelectedReport({
                sections: questionnaire,
                title: title,
                id: template.id,
                results: template.results ?? [],
              });
              setShowReport(true);
            }
          }}
          loading={isLoading}
        >
          Confirm
        </Button>

        <Button
          type="primary"
          size={isMobile ? "middle" : "large"}
          onClick={() => {
            saveTemplate();
          }}
          loading={isLoading}
        >
          Save as a new template
        </Button>

        <Button
          type="primary"
          size={isMobile ? "middle" : "large"}
          onClick={() => {
            saveReport();
          }}
          loading={isLoading}
        >
          {isEdit ? "Save change on template /  report" : "Save report"}
        </Button>
      </div>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={addComand}
        footer={null}
        onCancel={() => {
          myForm.resetFields();
          setAddComand(false);
        }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>
            Insert Command
          </h2>
          <Form className="col-10" form={myForm}>
            <Form.Item label={"For Section"} className="col-12 mr-5">
              <Select
                onChange={(e) => {
                  setQ({ ...q, sectionId: e });
                }}
                options={questionnaire.map((s, i) => {
                  return { value: i + 1, label: s.title };
                })}
              />
            </Form.Item>
            <Form.Item
              className="col-12 mr-5"
              labelCol={{ span: 5 }}
              label={"Title"}
              name={"command title"}
            >
              <Input
                onChange={(e) => {
                  setQ({ ...q, title: e.target.value });
                }}
              />
            </Form.Item>
          </Form>
          <div className="h-line" />
          <h2
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "-20px" }}
          >
            Available Commands
          </h2>
          <Radio.Group
            className="col-11"
            onChange={(e) => {
              setSelected(e.target.value);
              setNewCommand(e.target.value);
            }}
            value={selected}
          >
            <Space direction="vertical">
              {commands.map((c) => {
                return <Radio value={c.prompt}>{c.prompt}</Radio>;
              })}
              <Radio value={"other"}>
                {selected === "other" ? "" : "Add new command"}
                {selected === "other" ? (
                  <div className="d-flex flex-row">
                    <label>Text of command: </label>
                    <Input
                      onChange={(e) => {
                        setNewCommand(e.target.value);
                      }}
                      style={{
                        width: 270,
                        marginLeft: 10,
                      }}
                    />
                  </div>
                ) : null}
              </Radio>
            </Space>
          </Radio.Group>
          <div className="d-flex flex-row gap-4 col-12 justify-content-center">
            {" "}
            <Button
              // disabled={selected == "other"}
              type="primary"
              onClick={() => {
                var temp = questionnaire;
                temp[q.sectionId - 1] = {
                  ...temp[q.sectionId - 1],
                  commands: [
                    ...temp[q.sectionId - 1].commands,
                    { prompt: newCommand, title: q.title },
                  ],
                };
                myForm.resetFields();
                setQuestionnaire(temp);
                setAddComand(false);
              }}
            >
              {" "}
              Confirm{" "}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              myForm.resetFields();
              setEditComand(false);
            }}
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={editComandModal}
        footer={null}
        onCancel={() => {
          myForm.resetFields();
          setEditComand(false);
        }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>Edit Command</h2>

          <div className="d-flex flex-row gap-2 col-9">
            <span>For Section:</span>
            <Select
              value={q.sectionId}
              onChange={(e) => {
                setQ({ ...q, sectionId: e });
              }}
              options={questionnaire.map((s, i) => {
                if (s.id) {
                  return { value: s.id, label: s.title };
                }
                return { value: i + 1, label: s.title };
              })}
            />
          </div>
          <div className="d-flex flex-row gap-5 col-9">
            <span>Title:</span>
            <Input
              className="col-7"
              value={q.title}
              onChange={(e) => {
                setQ({ ...q, title: e.target.value });
              }}
            />
          </div>

          <div className="h-line" />

          <div className="d-flex flex-row gap-3 col-12  ">
            <span style={{ display: "flex" }}>
              <div
                style={{
                  color: "#ff4d4f",
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              >
                *
              </div>
              Prompt:{" "}
            </span>
            <Input.TextArea
              style={{ height: "maxContent" }}
              value={q.prompt}
              onChange={(e) => {
                setQ({ ...q, prompt: e.target.value });
              }}
            />
          </div>
          <div className="d-flex flex-row gap-4 col-12 justify-content-center">
            {" "}
            <Button
              type="primary"
              loading={isLoading}
              onClick={async () => {
                if (q.id) {
                  setIsLoading(true);
                  await editCommand(q);
                  setIsLoading(false);
                }
                var temp = questionnaire;
                var tempOtherSection = temp.filter((s) => {
                  return s.id != q.sectionId;
                });
                var tempCommand = temp.filter((s) => {
                  return s.id == q.sectionId;
                })[0];
                tempCommand.commands[q.index] = q;
                temp = [...tempOtherSection, tempCommand];
                setQuestionnaire(temp);
                myForm.resetFields();
                setEditComand(false);
              }}
            >
              {" "}
              Confirm{" "}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={addQuestion}
        onCancel={() => {
          setAddQuestion(false);
          myForm.resetFields();
        }}
        onOk={() => {
          if (q.sectionId && q.title) {
            var temp = questionnaire;
            temp[q.sectionId - 1] = {
              ...temp[q.sectionId - 1],
              questions: [
                ...(temp[q.sectionId - 1].questions ?? null),
                {
                  title: q.title,
                },
              ],
            };
            setQuestionnaire(temp);
            myForm.resetFields();
            setAddQuestion(false);
          }
        }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>Add Question</h2>
          <Form
            form={myForm}
            size="larg"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            className="col-10"
          >
            <Form.Item
              label={"Section"}
              name={"sectionId"}
              rules={[
                {
                  required: true,
                  message: "Please input section of question!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setQ({ ...q, sectionId: e });
                }}
                options={questionnaire.map((s, i) => {
                  return { value: i + 1, label: s.title };
                })}
              />
            </Form.Item>
            <Form.Item
              name={"titleofq"}
              label={"Text of qestion"}
              rules={[
                { required: true, message: "Please input text of question!" },
              ]}
            >
              <Input.TextArea
                onChange={(e) => {
                  setQ({ ...q, title: e.target.value });
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={addSection}
        onCancel={() => {
          setAddSection(false);
          myForm.resetFields();
        }}
        onOk={() => {
          if (q.title) {
            var temp = questionnaire;
            temp = [
              ...temp,
              {
                title: q.title,
                questions: [],
                commands: [],
              },
            ];
            setQuestionnaire(temp);
            myForm.resetFields();
            setAddSection(false);
          }
        }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>Add Section</h2>
          <Form
            form={myForm}
            size="larg"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 16,
            }}
            className="col-10"
          >
            <Form.Item
              label={"Title"}
              name={"section title"}
              rules={[
                { required: true, message: "Please input title of section!" },
              ]}
            >
              <Input
                onChange={(e) => {
                  setQ({ ...q, title: e.target.value });
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={editSectionModal}
        onCancel={() => {
          seteditSectionModal(false);
          myForm.resetFields();
        }}
        onOk={() => {
          if (q.title) {
            var temp = questionnaire;
            temp[q.sectionId] = q;
            setQuestionnaire(temp);
            myForm.resetFields();
            seteditSectionModal(false);
          }
        }}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>Edit Section</h2>
          <Form
            form={myForm}
            size="larg"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 16,
            }}
            className="col-10"
          >
            <Form.Item
              label={"Title"}
              name={"section title"}
              rules={[
                { required: true, message: "Please input title of section!" },
              ]}
            >
              <Input
                defaultValue={q.title}
                value={q.title}
                onChange={(e) => {
                  setQ({ ...q, title: e.target.value });
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

const Section = ({
  section,
  questionnaire,
  setQuestionnaire,
  index,
  editLoading,
  setEditLoading,
  showDeleteConfirm,
  seteditSectionModal,
  setQ,
  setIsSave,
  isMobile,
  setEditComand,
}) => {
  return (
    <div
      className="section d-flex flex-column gap-2"
      key={"reportSection" + section.id}
    >
      <div className="section-head">
        <h5
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsSave(false);
            setQ({ ...questionnaire[index], sectionId: index });
            seteditSectionModal(true);
          }}
          dir="rtl"
          className="col-11"
        >
          {" "}
          {section.title}
        </h5>
        <CloseCircleOutlined
          onClick={() => {
            showDeleteConfirm(section, "section");
          }}
          style={{
            fontSize: "18px",
            color: "rgba(255, 0, 0, 1)",
            marginLeft: "4.5%",
          }}
        />
      </div>
      {editLoading ? (
        <Spin />
      ) : (
        questionnaire[index].questions.map((q, i) => {
          return (
            <div
              key={"s" + index + "q" + i}
              className="section-q d-flex flex-row px-3 gap-2"
            >
              <Checkbox
                className={"col-1"}
                style={{ color: "rgba(46, 108, 223, 1)", fontWeight: "500" }}
              >
                {" "}
                Q {i + 1}
              </Checkbox>
              <Form.Item
                className="col-9 col-lg-10"
                initialValue={questionnaire[index].questions[i].title}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setIsSave(false);
                    var temp = questionnaire[index];
                    temp.questions[i] = {
                      ...temp.questions[i],
                      title: e.target.value,
                      question: e.target.value,
                    };
                    var temp2 = questionnaire;
                    temp2[index] = temp;
                    setQuestionnaire(temp2);
                  }}
                  defaultValue={questionnaire[index].questions[i].title}
                />
              </Form.Item>
              {!questionnaire[index].questions[i].isSensitive && (
                <UnlockFilled
                  key={`s${index}Q${i}u`}
                  onClick={async () => {
                    setEditLoading(true);
                    setIsSave(false);
                    var temp = questionnaire;
                    temp[index].questions[i] = {
                      ...temp[index].questions[i],
                      isSensitive: true,
                    };
                    await setQuestionnaire(temp);
                    setEditLoading(false);
                  }}
                  style={{
                    fontSize: "18px",
                  }}
                />
              )}
              {questionnaire[index].questions[i].isSensitive && (
                <LockFilled
                  key={`s${index}Q${i}l`}
                  onClick={async () => {
                    setEditLoading(true);
                    setIsSave(false);
                    var temp = questionnaire;
                    temp[index].questions[i] = {
                      ...temp[index].questions[i],
                      isSensitive: false,
                    };
                    await setQuestionnaire(temp);
                    setEditLoading(false);
                  }}
                  style={{
                    fontSize: "18px",
                  }}
                />
              )}
              <DeleteOutlined
                onClick={async () => {
                  showDeleteConfirm(q, "question", index);
                }}
                style={{
                  fontSize: "18px",
                  marginLeft: "10px",
                  // marginRight:"10px"
                }}
              />
            </div>
          );
        })
      )}
      {section.commands.map((command, hindex) => {
        return (
          <div className="section-c px-3 d-flex flex-row">
            <Checkbox style={{ marginRight: "30px" }} />
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => {
                setQ({ ...command, index: hindex });
                setEditComand(true);
              }}
              className="col-lg-11 col-9"
            >
              {" >> " + command.prompt}
            </h3>
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(command, "command", index);
              }}
              style={{
                fontSize: "18px",
                marginLeft: "7px",
                // marginRight:"10px"
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
export default NewQuestionnaire;
