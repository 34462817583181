import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "./routesList";

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        {ROUTES.map(({ path, Element }) => {
          return <Route path={path} key={path} element={Element} />;
        })}
        <Route path="*" element={<Navigate to={"/sign-in"} />} />
      </Routes>
    </>
  );
};
