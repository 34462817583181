import ReportManagement from "../Pages/Report";
import SignIn from "../Pages/Signin";

export const ROUTES = [
  {
    path: "/sign-in",
    Element: <SignIn />,
  },
  {
    path: "/report",
    Element: <ReportManagement />,
  },
  {
    path: "/report/:reportId",
    Element: <ReportManagement />,
  },
  {
    path: "/report/:reportId/confirm",
    Element: <ReportManagement />,
  },
];
