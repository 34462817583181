import { React, useState,useEffect } from "react";
import { Button, Form, Input, Checkbox, Modal } from "antd";
import { postWithoutToket } from "../../endpoints";
import "./styles.scss";
import { useAssets, useMobile } from "../../hooks/index";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [password, setPassword] = useState("");
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemmember] = useState(true);
  const navigator = useNavigate();
  const EmailValidate = (email) => {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!password) {
      setIsLoading(false);
      return;
    }
    if (EmailValidate(input)) {
      const body = { email: input, password: password };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status !== 201) {
       Modal.error({title:res})
      } else {
        Cookies.set("access_token", res.data.data.token);
        if (remember) {
          Cookies.set("isLogin", true, { expires: 365 });
        }
        Cookies.set("email", input);
        navigator("/report");
      }
    } else {
      Modal.error({title:"Invalid Email"})
      
    }
    setIsLoading(false);
  };



  return <div className="background">
      {!isLoading && (
        <img
          src={getFile("LightLogo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "20vw",
          }}
        />
      )}
      {isMobile && (
        <img
          src={getFile("Logo")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            maxWidth: "50vw",
            zIndex: "3",
          }}
        />
      )}
      <div className="signIn-box">
        <h1>Sign In</h1>
        <Form
          // size="large"
          name="signIn"
          layout={"vertical"}
          style={{ maxWidth: 900, width: "80%" }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>

          <div className="d-flex flex-row w-100">
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ span: 16 }}
              className="col-6"
            >
              <Checkbox
                value={remember}
                onChange={(e) => {
                  setRemmember(e.target.value);
                }}
              >
                Remember me
              </Checkbox>
            </Form.Item>
            
          </div>

          <Form.Item wrapperCol={{ span: 4, offset: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
              onClick={handleSubmit}
              style={{ marginLeft: "40%" }}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  
};
export default SignIn;
